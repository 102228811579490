/* global window */

import { onlyClosest } from '../services/OnlyClosest';

const CONFIG = {
  ATTR: 'data-tabs',
  ATTR_LINK: 'data-tabs-link',
  ATTR_CONTENT: 'data-tabs-content',
  CLASS_INIT: '-is-init',
  CLASS_ACTIVE: '-is-active',
};

const Tabs = {
  init() {
    const $tabsArray = window.document.querySelectorAll(`[${CONFIG.ATTR}]`);

    [...$tabsArray].forEach(($tabs) => {
      this.addEvents($tabs);
      this.setContents($tabs);
      $tabs.classList.add(CONFIG.CLASS_INIT);
    });
  },

  addEvents($tabs) {
    $tabs.addEventListener('click', onlyClosest(`[${CONFIG.ATTR_LINK}]`, (e, $link) => {
      if (e.target.classList.contains('-is-active')) {
        window.location.href = (e.target.href);
      }
      const idx = parseInt($link.getAttribute(CONFIG.ATTR_LINK), 10);
      this.setContents($tabs, idx > 0 ? idx : 0);
    }));
  },

  setContents($tabs, idx = 0) {
    const $contents = $tabs.querySelectorAll(`[${CONFIG.ATTR_CONTENT}]`);
    const $links = $tabs.querySelectorAll(`[${CONFIG.ATTR_LINK}]`);

    [...$contents].forEach(($content) => this.setActivity($content, idx, CONFIG.ATTR_CONTENT));
    [...$links].forEach(($link) => this.setActivity($link, idx, CONFIG.ATTR_LINK));
  },

  setActivity($item, activeIdx, attr) {
    const itemIdx = parseInt($item.getAttribute(attr), 10);
    if (itemIdx === activeIdx) {
      $item.classList.add(CONFIG.CLASS_ACTIVE);
      return;
    }
    $item.classList.remove(CONFIG.CLASS_ACTIVE);
  },
};

export default Tabs;
