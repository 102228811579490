/* global document, Event */

import MicroModal from 'micromodal';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const CONFIG = {
  ATTR: 'data-modal',
  ATTR_INNER: 'data-modal-inner',
  ATTR_OPEN: 'data-modal-open',
  ATTR_CLOSE: 'data-modal-close',
  CLASS_ACTIVE: '-is-open',
};

const MODAL_CONFIG = {
  openTrigger: CONFIG.ATTR_OPEN,
  closeTrigger: CONFIG.ATTR_CLOSE,
  openClass: CONFIG.CLASS_ACTIVE,
  disableScroll: false,
  onShow: ($modal, $trigger, event) => {
    if (event) {
      event.preventDefault();
    }
    disableBodyScroll($modal.querySelector(`[${CONFIG.ATTR_INNER}]`));
    $modal.dispatchEvent(new Event('show'));
  },
  onClose: ($modal, $trigger, event) => {
    if (event) {
      event.preventDefault();
    }
    enableBodyScroll($modal.querySelector(`[${CONFIG.ATTR_INNER}]`));
    $modal.dispatchEvent(new Event('hide'));
  },
};

const modal = {
  init() {
    this.moveModals();

    MicroModal.init(MODAL_CONFIG);
  },

  moveModals() {
    const $modals = document.querySelectorAll(`[${CONFIG.ATTR}]`);

    [...$modals].forEach(($modal) => {
      document.body.appendChild($modal);
    });
  },

  show(id) {
    MicroModal.show(id, MODAL_CONFIG);
  },

  close() {
    MicroModal.close();
  },
};

export default modal;
