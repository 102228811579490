/* global dataLayer, fetch, AbortController */

import algoliasearch from 'algoliasearch';
import instantsearch from 'instantsearch.js/es/index';
import { connectSearchBox } from 'instantsearch.js/es/connectors';
import { configure, analytics, infiniteHits } from 'instantsearch.js/es/widgets';

class SearchResults {
  constructor(options) {
    this.fetchShopContoller = null;

    this.options = {
      attr: 'data-search',
      appId: 'data-app-id',
      apiKey: 'data-api-key',
      indexName: 'data-index-name',
      translations: 'data-translations',
      config: {
        hitsPerPage: 8,
        facetsRefinements: {
          type: ['productCollection'],
        },
        facets: ['type'],
      },
      inputContainer: 'data-search-input-container',
      input: 'data-search-input',
      hits: 'data-search-hits',
      hitTemplate: 'data-hit-template',
      emptyContainer: 'data-search-empty',
      emptyTemplate: 'data-empty-template',
      shopTemplate: 'data-shop-template',
      shopLink: 'data-shop-link',
      promotions: '#active_promotions',
      shopAPI: 'data-search-shop-api',
      ...options,
    };
  }

  init = (window) => {
    const searchContainer = window.document.querySelector(
      `[${this.options.attr}]`,
    );
    this.hitTemplate = searchContainer.querySelector(
      `[${this.options.hitTemplate}]`,
    ).innerHTML;
    this.emptyContainer = searchContainer.querySelector(
      `[${this.options.emptyContainer}]`,
    );
    this.emptyTemplate = searchContainer.querySelector(
      `[${this.options.emptyTemplate}]`,
    ).innerHTML;
    this.shopTemplate = searchContainer.querySelector(
      `[${this.options.shopTemplate}]`,
    ).innerHTML;
    this.promotions = JSON.parse(
      window.document.querySelector(this.options.promotions).innerHTML,
    );
    this.translations = JSON.parse(
      searchContainer.getAttribute(this.options.translations),
    );
    this.shopAPI = searchContainer.getAttribute(this.options.shopAPI);
    this.inputContainer = window.document.querySelector(
      `[${this.options.inputContainer}]`,
    );
    this.input = this.inputContainer.querySelector(
      `[${this.options.input}]`,
    );

    const search = instantsearch({
      indexName: searchContainer.getAttribute(this.options.indexName),
      searchClient: algoliasearch(
        searchContainer.getAttribute(this.options.appId),
        searchContainer.getAttribute(this.options.apiKey),
      ),
      routing: false,
    });

    const customSearchBox = connectSearchBox(
      (renderOptions, isFirstRender) => {
        const { query, refine } = renderOptions;

        if (isFirstRender) {
          this.input.addEventListener('input', (event) => {
            refine(event.target.value);
          });
        }

        this.input.value = query;
      },
    );

    search.addWidgets([
      configure({
        ...this.options.config,
      }),
      customSearchBox({
        container: this.inputContainer,
      }),
      infiniteHits({
        container: `[${this.options.hits}]`,
        templates: {
          item: this.hitTemplate,
          empty: '',
          showMoreText: this.translations.showMoreLabel,
        },
        cssClasses: {
          list: 'search-results__items',
          item: 'search-results__item',
          loadMore: 'search-results__button button',
        },
        transformItems: (items) => items.map((item) => ({
          ...item,
          promotion:
            this.promotions.filter(
              (promotion) => promotion.id === item.promotion_id,
            )[0] || false,
        })),
      }),
      // custom widget for no-results (due to message outside the hits container)
      {
        render: ({ results }) => {
          this.abortFetchShopResults();

          if (results.hits.length) {
            this.emptyContainer.innerHTML = '';
            return;
          }

          if (!this.shopAPI) {
            this.emptyContainer.innerHTML = this.emptyTemplate;
            return;
          }

          this.fetchShopResults(results.query)
            .then((data) => {
              if (!data) {
                return;
              }

              if (data.hits > 0) {
                this.emptyContainer.innerHTML = this.shopTemplate;
                const shopLink = this.emptyContainer.querySelector(`[${this.options.shopLink}]`);
                const shopUrl = `${shopLink.getAttribute(this.options.shopLink)}${results.query}`;
                shopLink.setAttribute('href', shopUrl);
                return;
              }

              this.emptyContainer.innerHTML = this.emptyTemplate;
            });
        },
      },
      analytics({
        pushFunction(formattedParameters, state, results) {
          if (!state.query) {
            return;
          }

          dataLayer.push({
            event: 'VirtualSearch',
            virtualSearchKeyword: state.query,
            virtualSearchHits: results.nbHits,
          });
        },
      }),
    ]);

    search.start();
  };

  abortFetchShopResults = () => {
    if (!this.fetchShopContoller) {
      return;
    }

    this.fetchShopContoller.abort();
  };

  // eslint-disable-next-line no-return-await
  fetchShopResults = async (query) => {
    if (!this.shopAPI) {
      console.error(`Missing or incorrect "${this.options.shopAPI}"`); // eslint-disable-line no-console
      return null;
    }

    this.fetchShopContoller = new AbortController();
    const { signal } = this.fetchShopContoller;
    let aborted = false;

    const results = await fetch(`${this.shopAPI}${encodeURI(query)}`, {
      method: 'GET',
      signal,
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((e) => {
        if (e.name === 'AbortError') {
          aborted = true;
          return;
        }

        console.error(e); // eslint-disable-line no-console
      });

    return aborted ? null : (results || {});
  }
}

export default SearchResults;
