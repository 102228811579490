// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/

/* global window document */

import { debounce } from '../services/Debounce';

const ViewportCorrection = {
  init() {
    this.update();
    this.addEvents();
  },

  addEvents() {
    window.addEventListener('resize', debounce(this.update, 250));
  },

  update() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  },
};

export default ViewportCorrection;
