import Modal from './modal';

class Newsletter {
  constructor(options) {
    this.options = {
      popupId: 'newsletter-form',
      introForm: 'data-newsletter-intro-form',
      introEmail: 'data-newsletter-intro-form-email',
      form: 'data-newsletter-form',
      formEmail: 'data-newsletter-form-email',
      ...options,
    };
  }

  init = (window) => {
    this.introForm = window.document.querySelector(`[${this.options.introForm}]`);
    this.form = window.document.querySelector(`[${this.options.form}]`);

    this.addEvents();
  };

  addEvents = () => {
    this.introForm.addEventListener('submit', (e) => {
      e.preventDefault();

      const introEmail = this.introForm.querySelector(`[${this.options.introEmail}]`);
      const formEmail = this.form.querySelector(`[${this.options.formEmail}]`);
      formEmail.value = introEmail.value;

      Modal.show(this.options.popupId);
    });

    this.form.addEventListener('success', (event) => {
      if (event.detail.formName === this.form.getAttribute('name')) {
        Modal.close();
      }
    });
  };
}

export default Newsletter;
