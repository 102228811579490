/* global document, localStorage */

import { onlyClosest } from '../services/OnlyClosest';

const CONFIG = {
  ATTR: 'data-cookies',
  ATTR_CLOSE: 'data-cookies-close',
  CLASS_NOT_ACCEPTED: '-is-not-accepted',
  LOCAL_STORAGE_NAME: 'cookies-accepted',
};

const Cookies = {
  $cookies: null,
  init() {
    if (localStorage.getItem(CONFIG.LOCAL_STORAGE_NAME)) {
      return;
    }

    this.$cookies = document.querySelector(`[${CONFIG.ATTR}]`);

    this.showMessage();

    this.addEvents();
  },

  addEvents() {
    this.$cookies.addEventListener('click', onlyClosest(`[${CONFIG.ATTR_CLOSE}]`, () => {
      try {
        localStorage.setItem(CONFIG.LOCAL_STORAGE_NAME, true);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('Your web browser does not support storing settings locally. In Safari, the most common cause of this is using "Private Browsing Mode". Some settings may not save or some features may not work properly for you.');
      }

      this.hideMessage();
    }));
  },

  hideMessage() {
    this.$cookies.classList.remove(CONFIG.CLASS_NOT_ACCEPTED);
  },

  showMessage() {
    this.$cookies.classList.add(CONFIG.CLASS_NOT_ACCEPTED);
  },
};

export default Cookies;
