/* global window, document, FB, twttr */

const CONFIG = {
  ATTR: 'data-social-share',
};

const socialShare = {
  $shareLinks: [],

  init() {
    this.$shareLinks = document.querySelectorAll(`[${CONFIG.ATTR}]`);
    this.addEvents();
  },

  addEvents() {
    const { href } = window.location;

    [...this.$shareLinks].forEach(($link) => {
      $link.addEventListener('click', (e) => {
        const social = $link.getAttribute(CONFIG.ATTR);

        switch (social) {
          case 'facebook':
            e.preventDefault();
            FB.ui({
              method: 'share',
              display: 'popup',
              href,
            }, () => {});
            break;
          case 'twitter':
            e.preventDefault();
            twttr.events.bind('share', () => {});
            break;
          default:
            break;
        }
      });
    });
  },
};

export default socialShare;
