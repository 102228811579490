import { EVENT_SLIDE_CHANGE } from './slider';
import memoize from '../services/MemoizeHandler';

class VideoAutoplay {
  constructor(options) {
    this.options = {
      attr: 'data-video-autoplay',
      ...options,
    };
  }

  init = (window) => {
    window.document.body.addEventListener(EVENT_SLIDE_CHANGE, (e) => {
      const Slider = e.detail.slider;
      const info = Slider.getInfo();
      const $slides = [...e.detail.slides];
      const isLooped = info.slideCountNew > info.slideCount;
      const isEdgeSlide = (info.index < info.items) || (info.index >= (info.slideCountNew - info.items)); // eslint-disable-line max-len

      // stop if we are on edge slide in looped slider - to avoid blink
      if (isLooped && isEdgeSlide) {
        return;
      }

      // stop all videos in slider
      $slides.forEach(($slide, idx) => {
        const $video = $slide.querySelector(`[${this.options.attr}]`);

        if (!$video) {
          return;
        }

        const endedHandler = memoize(this.endedHandler, $video, Slider);

        // stop all videos and rewind all but previous
        this.stopVideo($video, idx !== info.indexCached, endedHandler);

        // play video on current slide
        if (idx === info.index) {
          setTimeout(() => {
            this.playVideo($video, Slider, endedHandler);
          }, 0);
        }
      });
    }, true);
  }

  stopVideo = ($video, rewind, endedHandler) => {
    const isPlaying = !!($video.currentTime > 0 && !$video.paused && !$video.ended && $video.readyState > 2); // eslint-disable-line max-len

    // stop all videos
    if (isPlaying) {
      $video.pause();
    }

    $video.removeEventListener('ended', endedHandler);

    $video.removeAttribute('loop'); // this is required for the `ended` event to work
    $video.removeAttribute('autoplay'); // we handle it!

    // rewind all videos but previous
    if (rewind) {
      $video.currentTime = 0; // eslint-disable-line no-param-reassign
    }
  }

  playVideo = ($video, Slider, endedHandler) => {
    const playPromise = $video.play();
    const { settings } = Slider;
    const videoDuration = $video.duration * 1000; // in ms

    if (!settings.autoplay || (settings.autoplayTimeout > videoDuration)) {
      return;
    }

    Slider.pause();

    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          $video.addEventListener('ended', endedHandler);
        })
        .catch((error) => {
          Slider.play();
          console.error(error); // eslint-disable-line no-console
        });
    }
  }

  endedHandler = ($video, Slider) => {
    Slider.goTo('next');
    Slider.play();
  }
}

export default VideoAutoplay;
