/* global document */

import { CONFIG as NAV_CONFIG } from './navigation';

const CONFIG = {
  ATTR: 'data-scroll-target',
  NAV_ATTR: NAV_CONFIG.ATTR,
  NAV_EVENT_RESIZE: NAV_CONFIG.EVENT_RESIZE,
};

const scrollTarget = {
  $scrollTargets: [],
  $nav: document.querySelector(`[${CONFIG.NAV_ATTR}]`),
  navHeight: 0,

  init() {
    this.$scrollTargets = [...document.querySelectorAll(`[${CONFIG.ATTR}]`)];

    if (!this.$nav) {
      return;
    }

    this.$nav.addEventListener(CONFIG.NAV_EVENT_RESIZE, (e) => {
      const { detail: { height } } = e;
      this.setTargets(height);
    });
  },

  setTargets(navHeight) {
    this.$scrollTargets.forEach(($target) => {
      $target.style.top = `-${navHeight}px`; // eslint-disable-line no-param-reassign
    });
  },
};

export default scrollTarget;
