/* global fetch, navigator */
import LocationSearchFactory from './LocationSearch';

class Geolocation {
  initialGeolocation = (apiKey) => fetch(`https://www.googleapis.com/geolocation/v1/geolocate?key=${apiKey}`, {
    method: 'POST',
  })
    .then((response) => response.json())
    .then((position) => ({
      latitude: position.location.lat,
      longitude: position.location.lng,
    }));

  geolocate = () => {
    if (navigator.geolocation) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }),
          reject,
        );
      });
    }

    return Promise.reject();
  };

  hasGeolocationPermission = () => 'permissions' in navigator && navigator.permissions
    .query({ name: 'geolocation' })
    .then((permission) => permission.state === 'granted');

  findClosestLocation = async (mode) => {
    const permission = await this.hasGeolocationPermission();

    if (!permission) {
      return null;
    }

    const locationSearch = LocationSearchFactory();
    const { latitude, longitude } = await this.geolocate();

    return locationSearch.findNearestLocation(latitude, longitude, mode);
  };
}

export default Geolocation;
