/* global document, window */

const CONFIG = {
  ATTR: 'data-scroll-hide',
};

const scrollOnHide = {
  $hideOnScroll: [],

  init() {
    this.$hideOnScroll = document.querySelectorAll(`[${CONFIG.ATTR}]`);
    this.addEvents();
  },

  addEvents() {
    document.addEventListener('scroll', () => {
      const currScrollPos = window.scrollY || document.documentElement.scrollTop;

      [...this.$hideOnScroll].forEach(($scrollItem) => {
        const itemPosition = $scrollItem.getBoundingClientRect().bottom;
        // eslint-disable-next-line no-param-reassign
        $scrollItem.style.opacity = (1 - Math.max(currScrollPos / itemPosition, 0));
      });
    });
  },
};

export default scrollOnHide;
