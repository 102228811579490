/* global document */

const CONFIG = {
  ATTR: 'data-toggle',
  ATTR_GROUP: 'data-toggle-group',
  ATTR_TRIGGER: 'data-toggle-show',
  ATTR_DEFAULT: 'data-toggle-default',
  CLASS_ACTIVE: '-is-active',
};

const toggle = {
  groups: {},
  $triggers: [],

  init() {
    this.$triggers = [...document.querySelectorAll(`[${CONFIG.ATTR_TRIGGER}]`)];

    this.groups = [...document.querySelectorAll(`[${CONFIG.ATTR_GROUP}]`)].reduce((groups, $element) => {
      const name = $element.getAttribute(CONFIG.ATTR_GROUP);
      const isDefault = $element.hasAttribute(CONFIG.ATTR_DEFAULT);

      return {
        ...groups,
        [name]: [
          ...(groups[name] || []),
          {
            $element,
            isDefault,
          },
        ],
      };
    }, {});

    this.addEvents();
  },

  addEvents() {
    this.$triggers.forEach(($trigger) => {
      $trigger.addEventListener('click', this.setActivity.bind(this, $trigger));
    });
  },

  setActivity($trigger, e) {
    const $target = document.querySelector(`[${CONFIG.ATTR}="${$trigger.getAttribute(CONFIG.ATTR_TRIGGER)}"]`);

    if (!$target) {
      return;
    }

    e.preventDefault();

    $trigger.classList.toggle(CONFIG.CLASS_ACTIVE);

    const isActive = $trigger.classList.contains(CONFIG.CLASS_ACTIVE);
    const groupName = $target.getAttribute(CONFIG.ATTR_GROUP);

    this.groups[groupName].forEach((content) => {
      if (isActive ? content.$element === $target : content.isDefault) {
        content.$element.classList.add(CONFIG.CLASS_ACTIVE);
      } else {
        content.$element.classList.remove(CONFIG.CLASS_ACTIVE);
      }
    });

    this.$triggers
      .filter(($element) => $element !== $trigger)
      .forEach(($element) => $element.classList.remove(CONFIG.CLASS_ACTIVE));
  },
};

export default toggle;
