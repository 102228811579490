/* global window document */
import Parallax from 'parallax-js';

import { debounce } from '../services/Debounce';
import { checkBreakpoint } from '../services/Breakpoints';

const CONFIG = {
  ATTR: 'data-parallax',
  ATTR_CHILDREN: 'data-depth',
};

const parallax = {
  $scenes: [],
  scenes: [],
  isInitialized: false,

  init() {
    this.handleResize = this.handleResize.bind(this);

    if (this.isEnabled) {
      this.create();
    }

    this.addEvents();
  },

  get isEnabled() {
    return checkBreakpoint('xs', 'md');
  },

  create() {
    this.$scenes = document.querySelectorAll(`[${CONFIG.ATTR}]`);
    this.scenes = [...this.$scenes].map(($scene) => new Parallax($scene, {
      limitY: 0,
      pointerEvents: true,
    }));

    this.isInitialized = true;
  },

  update() {
    this.scenes.forEach((scene) => (this.isEnabled ? scene.enable() : scene.disable()));

    if (!this.isEnabled) {
      [...this.$scenes].forEach(
        ($scene) => [...$scene.querySelectorAll(`[${CONFIG.ATTR_CHILDREN}]`)].forEach(
          ($children) => $children.setAttribute('style', ''),
        ),
      );
    }
  },

  addEvents() {
    window.addEventListener('resize', debounce(this.handleResize, 250));
  },

  handleResize() {
    this.isInitialized ? this.update() : this.create();
  },
};

export default parallax;
