import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';

const GoogleMap = ({
  apiKey,
  onGoogleApiLoaded,
  styles,
  locations,
  onLocationSelect,
  selectedLocation,
}) => (
  <GoogleMapReact
    bootstrapURLKeys={{ key: apiKey }}
    yesIWantToUseGoogleMapApiInternals
    defaultCenter={{ lat: 52.17, lng: 21.09 }}
    defaultZoom={1}
    onGoogleApiLoaded={onGoogleApiLoaded}
    options={{
      styles,
      fullscreenControl: false,
      maxZoom: 14,
    }}
  >
    {locations.map((marker) => (
      <Marker
        key={marker.id}
        id={marker.id}
        lat={marker._geoloc.lat} // eslint-disable-line no-underscore-dangle
        lng={marker._geoloc.lng} // eslint-disable-line no-underscore-dangle
        onSelect={onLocationSelect}
        isHighlighted={marker.salesRepresentative}
        isSelected={selectedLocation && marker.id === selectedLocation.id}
      />
    ))}
  </GoogleMapReact>
);

GoogleMap.propTypes = {
  apiKey: PropTypes.string.isRequired,
  onGoogleApiLoaded: PropTypes.func.isRequired,
  styles: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ).isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ).isRequired,
  onLocationSelect: PropTypes.func.isRequired,
  selectedLocation: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  ),
};

GoogleMap.defaultProps = {
  selectedLocation: null,
};

export default GoogleMap;
