/* global document */

import Tooltip from 'tooltip.js';

const CONFIG = {
  ATTR: 'data-tooltip',
  CLASS_ACTIVE: '-is-open',
};

const modal = {
  init() {
    const $triggers = document.querySelectorAll(`[${CONFIG.ATTR}]`);

    [...$triggers].forEach(($trigger) => new Tooltip($trigger, {
      html: true,
      template: '<div class="tooltip" role="tooltip"><span class="tooltip__arrow"></span><div class="tooltip__inner"></div></div>',
      innerSelector: '.tooltip__inner',
      title: $trigger.getAttribute(CONFIG.ATTR),
      placement: 'bottom',
      container: document.body,
      popperOptions: {
        modifiers: {
          flip: {
            enabled: true,
            boundariesElement: 'viewport',
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'window',
          },
        },
      },
    }));
  },
};

export default modal;
