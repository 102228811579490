/* global google */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchBox from './SearchBox';
import GoogleMap from './GoogleMap';
import LocationSearchFactory from '../../services/LocationSearch';
import Geolocation from '../../services/Geolocation';
import { geocodeCountry, geocodeCity } from '../../services/Geocode';
import { checkBreakpoint } from '../../services/Breakpoints';

const OFFSET_X = checkBreakpoint('xs', 'lg') ? 0 : -600; // sidebar
const OFFSET_Y = 0;

const propTypes = {
  mapStyles: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ).isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ).isRequired,
  googleMapsApiKey: PropTypes.string.isRequired,
  translations: PropTypes.objectOf(PropTypes.string).isRequired,
  localeSettings: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    shouldZoomToCountry: PropTypes.bool.isRequired,
    shouldGeolocate: PropTypes.bool.isRequired,
  }).isRequired,
};

class Locator extends Component {
  constructor(props) {
    super(props);
    const { localeSettings: { shouldGeolocate } } = props;

    this.locationSearch = LocationSearchFactory();

    this.state = {
      mapNode: null,
      country: null,
      geolocationCoordinates: null,
      geolocationConsent: false,
      searchResults: [],
      errorMessage: '',
      selectedLocation: null,
    };

    if (!shouldGeolocate) {
      return;
    }

    this.geolocation = new Geolocation();

    // geolocate without user consent
    this.geolocation
      .initialGeolocation(props.googleMapsApiKey)
      .then(this.setGeolocationPosition);

    // true geolocalization - requires user consent
    this.geolocation
      .geolocate()
      .then((coordinates) => {
        this.setGeolocationPosition(coordinates);
        this.setGeolocationConsent();
      })
      .then(() => {
        this.updateMapZoom();
        this.updateSelectedLocation();
      });
  }

  updateSearchResults = (searchResults) => {
    this.setState({
      searchResults,
    });
  };

  updateErrorMessage = (message) => {
    this.setState({
      errorMessage: message,
    });
  };

  setGeolocationConsent = () => {
    this.setState({
      geolocationConsent: true,
    });
  };

  setGeolocationPosition = (coordinates) => new Promise((resolve) => {
    this.setState(
      {
        geolocationCoordinates: coordinates,
      },
      resolve,
    );
  });

  setCountry = (country) => new Promise((resolve) => this.setState(
    {
      country,
    },
    resolve,
  ));

  findNearestLocation = () => {
    const { geolocationCoordinates } = this.state;

    return this.locationSearch.findNearestLocation(
      geolocationCoordinates.latitude,
      geolocationCoordinates.longitude,
    );
  };

  zoomToCountry = () => {
    const { country } = this.state;
    this.zoomToBounds(country.bounds);
  };

  zoomToLocations = (locations) => {
    const { mapNode } = this.state;
    const bounds = new google.maps.LatLngBounds();

    locations.forEach((location) => {
      const coordinates = new google.maps.LatLng(
        location._geoloc.lat, // eslint-disable-line no-underscore-dangle
        location._geoloc.lng, // eslint-disable-line no-underscore-dangle
      );
      bounds.extend(coordinates);
    });

    if (mapNode && locations.length) {
      this.zoomToBounds(bounds);
    }
  };

  zoomToBounds = (bounds) => {
    const { mapNode } = this.state;
    if (mapNode.getProjection()) {
      const topRightPoint = mapNode
        .getProjection()
        .fromLatLngToPoint(bounds.getNorthEast());
      mapNode.fitBounds(bounds);

      const scale = 2 ** mapNode.getZoom() || 0;
      const topRightPointWithOffset = new google.maps.Point(
        OFFSET_X / scale,
        OFFSET_Y / scale,
      );

      const newBoundsCoords = mapNode
        .getProjection()
        .fromPointToLatLng(
          new google.maps.Point(
            topRightPoint.x - topRightPointWithOffset.x,
            topRightPoint.y + topRightPointWithOffset.y,
          ),
        );

      bounds.extend(newBoundsCoords);
      mapNode.fitBounds(bounds);
    }
  };

  updateMapZoom = (ignoreGeolocation = false) => {
    const { mapNode, geolocationConsent } = this.state;

    if (!mapNode) {
      return;
    }

    if (!ignoreGeolocation && geolocationConsent) {
      this.findNearestLocation().then(this.zoomToLocations);
      return;
    }

    const { localeSettings: { locale, shouldZoomToCountry }, locations } = this.props;
    const firstLocationInCountry = locations.find(
      (location) => location.country.toLowerCase() === locale,
    );

    if (!shouldZoomToCountry || !firstLocationInCountry) {
      return;
    }

    const { _geoloc: { lat, lng } } = firstLocationInCountry;

    geocodeCountry({
      latitude: lat,
      longitude: lng,
    }).then((region) => {
      this.zoomToBounds(region.bounds);
    });
  };

  findLocationsInCountry = () => {
    const { country } = this.state;

    return this.locationSearch.findLocationsInCountry(country.code);
  };

  updateSelectedLocation = () => {
    const { geolocationConsent } = this.state;

    if (geolocationConsent) {
      this.findNearestLocation().then((locations) => {
        if (locations.length) {
          this.setSelectedLocation(locations[0].id);
        }
      });
    }
  };

  checkLocationsAvailability = () => {
    const { translations } = this.props;

    this.findLocationsInCountry().then((locations) => {
      if (
        !locations.filter((location) => !location.salesRepresentative).length
      ) {
        this.updateErrorMessage(translations.noShowroomInCountry);
      }
    });
  };

  onGoogleApiLoaded = ({ map }) => {
    const { geolocationCoordinates } = this.state;
    const { localeSettings: { shouldGeolocate } } = this.props;

    this.setState(
      {
        mapNode: map,
      },
      () => {
        if (!shouldGeolocate) {
          this.updateMapZoom();
          return;
        }

        geocodeCountry(geolocationCoordinates)
          .then(this.setCountry)
          .then(() => {
            this.updateMapZoom();
            this.checkLocationsAvailability();
            this.updateSelectedLocation();
          });
      },
    );
  };

  onInputChangeHandler = async (query) => {
    this.unselectLocation();
    this.updateErrorMessage('');

    if (!query) {
      this.clearResults();
      return;
    }

    const results = await this.getResults(query);
    this.showResults(results);
  };

  getResults = async (query) => {
    const results = await this.locationSearch.search(query);
    if (results.length) {
      return results;
    }

    try {
      const cityCoordinates = await geocodeCity(query);
      const { translations } = this.props;

      this.updateErrorMessage(translations.noShowroomInCity);

      return await this.locationSearch.findNearestLocation(
        cityCoordinates.lat,
        cityCoordinates.lng,
      );
    } catch (e) {
      this.showNoResultsError();
      return [];
    }
  };

  showResults = (results) => {
    if (!results.length) {
      this.showNoResultsError();
      return;
    }

    this.updateSearchResults(results);
    this.zoomToLocations(results);
  };

  showNoResultsError = () => {
    const { translations } = this.props;
    this.updateSearchResults([]);
    this.zoomToCountry();
    this.updateErrorMessage(translations.noShowroom);
  };

  clearResults = () => {
    this.updateSearchResults([]);
    this.updateErrorMessage('');
  };

  unselectLocation = () => {
    this.setState({
      selectedLocation: null,
    });
  };

  setSelectedLocation = (id) => {
    this.setState({
      selectedLocation: this.getLocationData(id),
    });
  };

  resetMap = () => {
    this.setState({
      selectedLocation: null,
      searchResults: [],
    });
    this.updateMapZoom(true);
  };

  getLocationData = (id) => {
    const { locations } = this.props;

    return locations.filter((location) => location.id === id)[0];
  };

  render() {
    const {
      googleMapsApiKey,
      mapStyles,
      locations,
      translations,
    } = this.props;
    const { selectedLocation, searchResults, errorMessage } = this.state;

    return (
      <div className="locator">
        <div className="locator__map">
          <GoogleMap
            apiKey={googleMapsApiKey}
            onGoogleApiLoaded={this.onGoogleApiLoaded}
            styles={mapStyles}
            locations={locations}
            onLocationSelect={this.setSelectedLocation}
            selectedLocation={selectedLocation}
          />
        </div>
        <div className="container locator__search-box-container">
          <div className="locator__search-box">
            <SearchBox
              onChange={this.onInputChangeHandler}
              results={searchResults}
              onSelect={this.setSelectedLocation}
              onClose={this.resetMap}
              selectedResult={selectedLocation}
              message={errorMessage}
              translations={translations}
            />
          </div>
        </div>
      </div>
    );
  }
}

Locator.propTypes = propTypes;

export default Locator;
