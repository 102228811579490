/* global fetch */
import Modal from './modal';
import Geolocation from '../services/Geolocation';

class ShowroomContactForm {
  constructor(options) {
    this.options = {
      formContainer: 'data-contact-form',
      popupId: 'contact-form',
      showroomSelect: null,
      ajaxForm: null,
      ...options,
    };
  }

  init = (window) => {
    this.window = window;
  };

  open = (url, showroomId = null, mode) => {
    this.formContainer = this.window.document.querySelector(`[${this.options.formContainer}]`);

    if (!this.formContainer) {
      return;
    }

    Modal.show(this.options.popupId);

    this.initForm(url, showroomId, mode);
  };

  initForm = async (url, showroomId = null, mode) => {
    this.formContainer.innerHTML = await fetch(url)
      .then((response) => response.json())
      .then((data) => data.form_html);

    this.options.showroomSelect.init(this.window);
    if (showroomId) {
      this.options.showroomSelect.setShowroom(showroomId);
    }

    if (!showroomId) {
      const geolocation = new Geolocation();

      const showroom = await geolocation.findClosestLocation(mode);

      if (showroom) {
        this.options.showroomSelect.setShowroom(showroom.find((_) => _).id);
      }
    }

    const form = this.formContainer.querySelector('form');

    this.options.ajaxForm.addForm(form);

    this.addEvents(form);
  };

  addEvents = (form) => {
    form.addEventListener('error', (event) => {
      if (event.detail.formName === form.getAttribute('name')) {
        this.options.showroomSelect.init(this.window);
      }
    });

    form.addEventListener('success', (event) => {
      if (event.detail.formName === form.getAttribute('name')) {
        Modal.close();
        this.formContainer.removeChild(form);
      }
    });
  };
}

export default ShowroomContactForm;
