/* global window document CustomEvent */

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { ResizeObserver } from '@juggle/resize-observer';

import { onlyClosest } from '../services/OnlyClosest';
import { checkBreakpoint } from '../services/Breakpoints';
import { debounce } from '../services/Debounce';

export const CONFIG = {
  ATTR: 'data-nav',
  ATTR_TRIGGER: 'data-nav-toggle',
  ATTR_MENU: 'data-nav-menu',
  ATTR_SUBMENU: 'data-nav-submenu',
  ATTR_SUBMENU_TRIGGER: 'data-nav-submenu-toggle',
  CLASS_ACTIVE: '-is-active',
  CLASS_SUBMENU_ACTIVE: '-is-submenu-active',
  EVENT_RESIZE: 'resize',
};

const Navigation = {
  isActive: false,
  openSubmenu: null,
  viewportWidth: 0,
  height: 0,

  init() {
    this.$el = document.querySelector(`[${CONFIG.ATTR}]`);
    this.$menu = this.$el.querySelector(`[${CONFIG.ATTR_MENU}]`);
    this.$submenus = [...this.$el.querySelectorAll(`[${CONFIG.ATTR_SUBMENU}]`)];
    this.$submenuTriggers = [...this.$el.querySelectorAll(`[${CONFIG.ATTR_SUBMENU_TRIGGER}]`)];

    this.update = this.update.bind(this);
    this.setHeight = this.setHeight.bind(this);

    this.update();
    this.addEvents();
  },

  addEvents() {
    this.$el.addEventListener('click', onlyClosest(`[${CONFIG.ATTR_TRIGGER}]`, () => {
      this.toggleActivity();
    }));

    this.$el.addEventListener('click', onlyClosest(`[${CONFIG.ATTR_SUBMENU_TRIGGER}]`, (e) => {
      const name = e.target.closest(`[${CONFIG.ATTR_SUBMENU_TRIGGER}]`).getAttribute(CONFIG.ATTR_SUBMENU_TRIGGER);
      this.toggleSubmenuActivity(name);
    }));

    window.addEventListener('resize', debounce(this.update, 250));

    const navHeightObserver = new ResizeObserver(() => this.setHeight());
    navHeightObserver.observe(this.$el);
  },

  update() {
    if (window.innerWidth === this.viewportWidth) {
      return;
    }

    enableBodyScroll(this.$menu);
    this.setActivity(false);
    this.setSubmenuActivity(null);
    this.viewportWidth = window.innerWidth;
  },

  toggleActivity() {
    this.setActivity(!this.isActive);
    this.setSubmenuActivity(null);
  },

  setActivity(isActive) {
    this.isActive = isActive;

    if (isActive) {
      this.$menu.classList.add(CONFIG.CLASS_ACTIVE);
      if (!checkBreakpoint('xl')) {
        disableBodyScroll(this.$menu);
      }
      return;
    }

    this.$menu.classList.remove(CONFIG.CLASS_ACTIVE);
    enableBodyScroll(this.$menu);
  },

  setHeight() {
    this.height = this.$el.offsetHeight;
    this.$el.dispatchEvent(new CustomEvent(CONFIG.EVENT_RESIZE, {
      detail: {
        height: this.height,
      },
    }));
  },

  toggleSubmenuActivity(name) {
    this.setSubmenuActivity(this.openSubmenu === name ? null : name);
    this.setActivity(false);
  },

  setSubmenuActivity(name) {
    this.openSubmenu = name;

    this.toggleActivityIfAttrValue(this.$submenus, CONFIG.ATTR_SUBMENU, name);
    this.toggleActivityIfAttrValue(this.$submenuTriggers, CONFIG.ATTR_SUBMENU_TRIGGER, name);
    this.$menu.classList.toggle(CONFIG.CLASS_SUBMENU_ACTIVE, !!name);
  },

  toggleActivityIfAttrValue($elements, attr, value) {
    $elements.forEach(($el) => {
      const isCurrent = value && $el.getAttribute(attr) === value;
      $el.classList.toggle(CONFIG.CLASS_ACTIVE, isCurrent);
    });
  },
};

export default Navigation;
