/* global document */

const CONFIG = {
  ATTR: 'data-documents',
  ATTR_ARCHIVE: 'data-documents-hidden-content',
  ATTR_SHOW: 'data-documents-load',
  CLASS_HIDDEN: '-is-hidden',
};

const documentShowMore = {

  $showButtons: [],

  init() {
    const documentsWrappers = document.querySelector(`[${CONFIG.ATTR}]`);
    if (!documentsWrappers) {
      return;
    }
    this.hiddenDocs = documentsWrappers.querySelector(`[${CONFIG.ATTR_ARCHIVE}]`);
    this.$showButtons = documentsWrappers.querySelectorAll(`[${CONFIG.ATTR_SHOW}]`);
    this.addEvents();
  },
  addEvents() {
    [...this.$showButtons].forEach(($singleButton) => {
      $singleButton.addEventListener('click', () => {
        this.hiddenDocs.classList.remove(`${CONFIG.CLASS_HIDDEN}`);
      });
    });
  },
};

export default documentShowMore;
