/* global window, sessionStorage */

const CONFIG = {
  SOURCE_PARAM: 'utm_source',
  SESSION_STORAGE_NAME: 'utm_to_link',
};

const AddUtmToLinks = {
  init(externalUrl, source) {
    this.checkUrl(source);
    this.modifyLinks(externalUrl);
  },

  checkUrl(source) {
    const queryString = window.location.search;

    if (!queryString) {
      return;
    }

    const params = new URLSearchParams(queryString.slice(1));

    if (params.get(CONFIG.SOURCE_PARAM) !== source) {
      return;
    }

    this.saveToSessionStorage(queryString);
  },

  readFromSessionStorage() {
    return sessionStorage.getItem(CONFIG.SESSION_STORAGE_NAME);
  },

  saveToSessionStorage(value) {
    try {
      sessionStorage.setItem(CONFIG.SESSION_STORAGE_NAME, value);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Your web browser does not support storing settings locally.');
    }
  },

  modifyLinks(externalUrl) {
    const queryString = this.readFromSessionStorage();

    if (!queryString) {
      return;
    }

    const params = new URLSearchParams(queryString.slice(1));

    [...window.document.querySelectorAll('a')].filter((a) => a.hostname === externalUrl).forEach((a) => {
      const url = new URL(a.getAttribute('href'));

      params.forEach((value, key) => {
        url.searchParams.append(key, value);
      });

      a.setAttribute('href', url);
    });
  },
};

export default AddUtmToLinks;
