/* global document */

export default class UrlParser {
  constructor(url) {
    this.url = url;
  }

  getQueryParam(name) {
    const anhor = document.createElement('a');
    anhor.setAttribute('href', this.url);

    return anhor.search
      .split('&')
      .map((param) => param.split('=').map(decodeURIComponent))
      .filter((param) => param[0].includes(name))
      .map((param) => param[1])
      .find(() => true);
  }
}
