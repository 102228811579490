/* global document, window, sessionStorage */

import queryString from 'query-string';

import { onlyClosest } from '../services/OnlyClosest';
import makeRequest from '../services/MakeRequest';
import UrlParser from '../services/UrlParser';

const CONFIG = {
  ATTR: 'data-load-more',
  ATTR_CONTENT: 'data-load-more-content',
  ATTR_BUTTON: 'data-load-more-button',
  CLASS_LOADING: '-is-loading',
  CLASS_HIDDEN: '-is-hidden',
};

const loadMore = {
  $sections: [],

  init() {
    this.$sections = [...document.querySelectorAll(`[${CONFIG.ATTR}]`)];
    this.addEvents();
    this.loadPagesFromState();
  },

  addEvents() {
    this.$sections.forEach(($section) => {
      $section.addEventListener('click', onlyClosest(`[${CONFIG.ATTR_BUTTON}]`, this.loadMore.bind(this, $section)));
    });
  },

  loadMore($section, e, $trigger) {
    const url = $trigger.getAttribute('href');

    if (!url) {
      return;
    }

    const pageNo = (new UrlParser(url)).getQueryParam('pageNumber');

    window.history.pushState({}, undefined, `#${pageNo}`);
    e.preventDefault();

    $trigger.classList.add(CONFIG.CLASS_HIDDEN);
    $section.classList.add(CONFIG.CLASS_LOADING);

    makeRequest(queryString.stringifyUrl({
      url,
      query: { ajax: true },
    }), (response) => {
      $section.classList.remove(CONFIG.CLASS_LOADING);

      if (response.status === 'error') {
        $trigger.classList.remove(CONFIG.CLASS_HIDDEN);
        return;
      }

      if (response.status === 'success') {
        sessionStorage.setItem(this.getStorageKey($section, pageNo), response.response);
        this.appendPage($section, response.response);
      }
    });
  },

  loadPagesFromState() {
    const state = parseInt(window.location.hash.substr(1), 10);

    this.$sections.forEach(($section) => {
      for (let pageNo = 2; pageNo <= state; pageNo += 1) {
        const key = this.getStorageKey($section, pageNo);
        const data = sessionStorage.getItem(key);
        const isLastIteration = pageNo === state;

        if (data) {
          if (isLastIteration) {
            [...$section.querySelectorAll(`[${CONFIG.ATTR_BUTTON}]`)].forEach(($button) => {
              $button.classList.add(CONFIG.CLASS_HIDDEN);
            });
          }
          this.appendPage($section, data);
        }
      }
    });
  },

  getStorageKey($section, pageNo) {
    const key = $section
      .querySelector(`[${CONFIG.ATTR_BUTTON}]`)
      .getAttribute(CONFIG.ATTR_BUTTON);

    return `load-more-${key}-${pageNo}`;
  },

  appendPage($section, html) {
    const $content = $section.querySelector(`[${CONFIG.ATTR_CONTENT}]`) || $section;
    $content.insertAdjacentHTML('beforeend', html);
  },
};

export default loadMore;
