/* global document */

const CONFIG = {
  container: 'data-slideshow',
  item: 'data-slideshow-item',
  active: '-is-active',
  interval: 6 * 1000,
};

const GallerySlideshow = {
  init() {
    [...document.querySelectorAll(`[${CONFIG.container}]`)].forEach((gallery) => {
      const items = [...gallery.querySelectorAll(`[${CONFIG.item}]`)];

      let active = items.findIndex((item) => item.classList.contains(CONFIG.active));

      setInterval(() => {
        active = (active + 1) % items.length;

        items.forEach((item, idx) => {
          if (idx === active) {
            item.classList.add(CONFIG.active);
          } else {
            item.classList.remove(CONFIG.active);
          }
        });
      }, CONFIG.interval);
    });
  },
};

export default GallerySlideshow;
