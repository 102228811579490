/* global window */
/* eslint-disable no-console */
import { getSetting } from './GetSetting';

const breakpoints = getSetting('breakpoints');
const RESOLUTION_4K = 4096;

// convert BPs format from { xs: 0, sm: 576, md: 768, ...}
// to ranges { xs: { from: 0, to: 575 }, sm: { from: 576, to: 767 }, ...}
const breakpointRanges = (breakpointsObject) => Object.entries(breakpointsObject)
  .sort((a, b) => b[1] - a[1])
  .map((bp) => ({ name: bp[0], from: bp[1], to: null }))
  .reduce((res, cur, idx, arr) => ({
    ...res,
    [cur.name]: {
      from: cur.from,
      to: (idx === 0 ? RESOLUTION_4K : arr[idx - 1].from) - 1,
    },
  }), {});

const checkBreakpoint = (breakpointFrom, breakpointTo = null) => {
  if (!Object.prototype.hasOwnProperty.call(breakpoints, breakpointFrom)) {
    console.warn(`there is no '${breakpointFrom}' BP`);
    return false;
  }

  let isSecondBpSet = false;

  if (breakpointTo) {
    if (!Object.prototype.hasOwnProperty.call(breakpoints, breakpointTo)) {
      console.warn(`there is no '${breakpointTo}' BP, omitted`);
    } else {
      isSecondBpSet = true;
    }
  }

  const breakpointsRanges = breakpointRanges(breakpoints);

  if (isSecondBpSet) {
    return window.innerWidth >= breakpointsRanges[breakpointFrom].from
      && window.innerWidth <= breakpointsRanges[breakpointTo].to;
  }

  return window.innerWidth >= breakpointsRanges[breakpointFrom].from;
};

export { checkBreakpoint };
export default breakpoints;
