/* global window */
import '@babel/polyfill';
import 'events-polyfill';
import 'url-polyfill';
import 'element-closest';
import 'objectFitPolyfill';
import 'whatwg-fetch';

import Icons from './components/icons';
import Navigation from './components/navigation';
import Toggle from './components/toggle';
import GallerySlideshow from './components/gallery-slideshow';
import Slider from './components/slider';
import Modal from './components/modal';
import ScrollOnHide from './components/scroll-on-hide';
import { init as ScrollToInit } from './components/scroll-to';
import ScrollTarget from './components/scroll-target';
import ScrollActivate from './components/scroll-activate';
import Sticky from './components/sticky';
import Tabs from './components/tabs';
import Tooltip from './components/tooltip';
import Parallax from './components/parallax';
import SocialShare from './components/social-share';
import Player from './components/player';
import LoadMore from './components/load-more';
import Animation from './components/animation';
import Cookies from './components/cookies';
import FitFontSize from './components/fit-font-size';
import Locator from './components/Locator/index';
import Search from './components/Search';
import AjaxForm from './components/ajax-form';
import ShowroomContactForm from './components/showroom-contact-form';
import ShowroomSelect from './components/showroom-select';
import ClosestShowroom from './components/closest-showroom';
import OpenShowroomContactForm from './components/open-showroom-contact-form';
import VideoAutoplay from './components/video-autoplay';
import Newsletter from './components/newsletter';
import ViewportCorrection from './components/viewport-correction';
import DocumentShowMore from './components/document-show-more';
import OpenModal from './components/open-modal';
import AddUtmToLinks from './components/add-utm-to-links';

Icons.init();
Navigation.init();
Toggle.init();
GallerySlideshow.init();
Slider.init();
Modal.init();
ScrollOnHide.init();
ScrollToInit();
ScrollTarget.init();
ScrollActivate.init();
Sticky.init();
Tabs.init();
Tooltip.init();
Parallax.init();
SocialShare.init();
Player.init();
LoadMore.init();
Animation.init();
Cookies.init();
ViewportCorrection.init();
DocumentShowMore.init();
AddUtmToLinks.init('sklep.kler.eu', 'rmf');

(new FitFontSize()).init(window);

const showroomSelect = new ShowroomSelect();

const ajaxForm = new AjaxForm();
ajaxForm.init(window);

const showroomContactForm = new ShowroomContactForm({ showroomSelect, ajaxForm });
showroomContactForm.init(window);

(new Locator({ showroomContactForm })).init(window);

(new Search()).init(window);

(new ClosestShowroom({ showroomContactForm })).init(window);

(new OpenShowroomContactForm({ showroomContactForm })).init(window);

(new VideoAutoplay()).init(window);

(new Newsletter()).init(window);

(new OpenModal()).init(window);
