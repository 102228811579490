/* global fetch, FormData, CustomEvent */
import Notification from './notification';

class AjaxForm {
  constructor(options) {
    this.options = {
      attr: 'data-ajax',
      attrPageUrl: 'data-location-url',
      attrRedirectUrl: 'data-redirect-url',
      ...options,
    };
  }

  init(window) {
    this.window = window;

    this.forms = [
      ...window.document.querySelectorAll(`form[${this.options.attr}]`),
    ];

    this.notification = new Notification();

    this.initForms(this.forms);
  }

  initForms = (forms) => {
    forms.forEach((form) => {
      const pageUrlHiddenInput = form.querySelector(`[${this.options.attrPageUrl}]`);
      if (pageUrlHiddenInput) {
        pageUrlHiddenInput.value = this.window.location.href;
      }
      this.addEvents(form);
    });
  };

  emitEvent = (form, eventType) => {
    const customEvent = new CustomEvent(eventType, {
      detail: { formName: form.getAttribute('name') },
    });
    form.dispatchEvent(customEvent);
  };

  addEvents = (form) => {
    form.addEventListener('submit', (event) => {
      event.preventDefault();

      this.sendFormData(event.target);
    });
  };

  sendFormData = (form) => {
    const formData = new FormData(form);

    fetch(form.getAttribute('action'), {
      method: 'POST',
      credentials: 'same-origin',
      body: formData,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
      .then(this.onSubmit)
      .then((response) => this.onSuccess(response, form))
      .catch((error) => this.onError(error, form));
  };

  onSubmit = (response) => {
    if (response.status >= 200 && response.status < 300) {
      return response.json();
    }

    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  };

  onSuccess = (response, form) => {
    this.emitEvent(form, 'success');

    const redirectUrl = form.getAttribute(this.options.attrRedirectUrl);
    if (redirectUrl) {
      this.window.location.href = redirectUrl;
      return;
    }

    this.notification.success(response.message);
  };

  onError = (error, form) => {
    try {
      error.response.json()
        .then((response) => {
          form.innerHTML = response.form_html; // eslint-disable-line no-param-reassign

          this.emitEvent(form, 'error');

          this.notification.error(response.message);
        });
    } catch (e) {
      this.notification.error('Wystąpił błąd, proszę spróbować później.');
      console.error(e); // eslint-disable-line no-console
    }
  };

  addForm = (form) => {
    this.initForms([form]);
  }
}

export default AjaxForm;
