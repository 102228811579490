import React from 'react';
import ReactDOM from 'react-dom';
import Locator from './Locator';
import Context from './context';

class LocatorInit {
  constructor(options) {
    this.options = {
      container: 'data-locator',
      styles: 'data-locator-styles',
      googleMapsApiKey: 'data-google-maps-api-key',
      showroomContactForm: null,
      contactFormUrl: 'data-contact-form-url',
      translations: 'data-translations',
      settings: 'data-locale-settings',
      ...options,
    };
  }

  init(window) {
    this.container = window.document.querySelector(`[${this.options.container}]`);

    if (this.container) {
      const locations = JSON.parse(this.container.getAttribute(this.options.container));
      const styles = JSON.parse(this.container.getAttribute(this.options.styles));
      const googleMapsApiKey = this.container.getAttribute(this.options.googleMapsApiKey);
      const translations = JSON.parse(this.container.getAttribute(this.options.translations));
      const settings = JSON.parse(this.container.getAttribute(this.options.settings));

      ReactDOM.render(
        <Context.Provider value={{ openContactForm: this.openContactForm }}>
          <Locator
            locations={locations}
            mapStyles={styles}
            googleMapsApiKey={googleMapsApiKey}
            translations={translations}
            localeSettings={settings}
          />
        </Context.Provider>,
        this.container,
      );
    }
  }

  openContactForm = (showroomId) => {
    const formUrl = this.container.getAttribute(this.options.contactFormUrl);
    this.options.showroomContactForm.open(formUrl, showroomId);
  };
}

export default LocatorInit;
