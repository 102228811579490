/* eslint-disable jsx-a11y/click-events-have-key-events,
jsx-a11y/no-noninteractive-element-interactions,
jsx-a11y/label-has-associated-control,
jsx-a11y/label-has-for */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { debounce } from '../../services/Debounce';
import Context from './context';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  results: PropTypes.arrayOf(PropTypes.object),
  selectedResult: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  ),
  message: PropTypes.string,
  translations: PropTypes.objectOf(PropTypes.string).isRequired,
};

const defaultProps = {
  results: [],
  message: '',
  selectedResult: null,
};

class SearchBox extends Component {
  onChangeHandler = debounce((e) => {
    const { onChange } = this.props;
    onChange(e.target.value);
  }, 500);

  renderSelectedLocation = () => {
    const { selectedResult } = this.props;
    const { translations } = this.props;
    const { onClose } = this.props;
    if (!selectedResult) {
      return null;
    }

    const { lat, lng } = selectedResult._geoloc; // eslint-disable-line no-underscore-dangle

    if (selectedResult.salesRepresentative) {
      return (
        <div className="contact-details">
          <p className="text">
            {translations.contact}
          </p>
          <p className="text">
            {selectedResult.name}
          </p>
          {selectedResult.phone
            && (
            <a
              href={`tel:${selectedResult.phone}`}
              className="link contact-details__phone"
            >
              {selectedResult.phone}
            </a>
            )}
          {selectedResult.email
            && (
            <a
              href={`mailto:${selectedResult.email}`}
              className="link contact-details__email"
            >
              {selectedResult.email}
            </a>
            )}
        </div>
      );
    }

    return (
      <div className="contact-details">
        <button
          type="button"
          className="modal__close button button--secondary button--icon button--close"
          title="close"
          aria-label="close"
          onClick={() => {
            onClose();
          }}
        >
          <span className="button__icon icon">
            <svg><use xlinkHref="#icon-close" /></svg>
          </span>
        </button>
        <p className="label contact-details__type">
          {selectedResult.type}
        </p>
        <p className="title title--h4 contact-details__name">
          {selectedResult.name}
        </p>
        <p className="text contact-details__address">
          {`${selectedResult.street}, ${selectedResult.postCode} ${selectedResult.city}`}
        </p>
        <p className="contact-details__hours">
          {selectedResult.openingHours.split('\n').map((item, idx) => (
            <Fragment
              key={`line_${idx}` /* eslint-disable-line react/no-array-index-key */}
            >
              { item }
              <br />
            </Fragment>
          ))}
        </p>
        {selectedResult.phone
          && (
          <a
            href={`tel:${selectedResult.phone}`}
            className="link contact-details__phone"
          >
            {selectedResult.phone}
          </a>
          )}
        {selectedResult.additionalInfo && (
          <>
            {selectedResult.additionalInfo}
            <br />
          </>
        )}
        <div className="contact-details__buttons">
          <Context.Consumer>
            {({ openContactForm }) => (
              <a
                href={`mailto:${selectedResult.email}`}
                className="button button--primary"
              >
                {translations.button}
              </a>
            )}
          </Context.Consumer>
          <a
            href={`https://www.google.com/maps/dir/Current+Location/${lat},${lng}`}
            className="button button--icon contact-details__button"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            <span className="button__icon icon">
              <svg>
                <use xlinkHref="#icon-marker-map" />
              </svg>
            </span>
          </a>
        </div>
      </div>
    );
  };

  renderResults = () => {
    const { results, selectedResult, onSelect } = this.props;

    if (!results.length || selectedResult) {
      return null;
    }

    return (
      <ul>
        {results
          .sort((a, b) => {
            const first = `${a.city} ${a.name} ${a.address}`;
            const second = `${b.city} ${b.name} ${b.address}`;

            if (first < second) { return -1; }
            if (first > second) { return 1; }
            return 0;
          })
          .map((result) => (
            <li
              className="search-box__result"
              key={result.objectID}
              onClick={() => {
                onSelect(result.objectID);
              }}
            >
              <p className="text search-box__result-name">
                {result.name}
              </p>
              <p className="text search-box__result-address">
                {result.address}
              </p>
            </li>
          ))}
      </ul>
    );
  };

  render() {
    const { message } = this.props;
    const { translations } = this.props;
    return (
      <div className="search-box">
        <label className="search-box__input-wrapper">
          <input
            className="mega-input search-box__input"
            type="text"
            onChange={(e) => { e.persist(); this.onChangeHandler(e); }}
            placeholder={translations.find}
          />
          <span className="icon search-box__icon">
            <svg>
              <use xlinkHref="#icon-search" />
            </svg>
          </span>
        </label>

        {message
          && (
          <div className="search-box__error">
            {message}
          </div>
          )}

        {this.renderSelectedLocation()}

        {this.renderResults()}
      </div>
    );
  }
}

SearchBox.propTypes = propTypes;
SearchBox.defaultProps = defaultProps;

export default SearchBox;
