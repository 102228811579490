/* global window, document */

import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

const CONFIG = {
  ATTR: 'data-scroll-to',
};

export default class ScrollTo {
  constructor($trigger) {
    this.$trigger = $trigger;
    this.init();

    return $trigger;
  }

  init() {
    this.$trigger.addEventListener('click', (e) => {
      const href = this.$trigger.getAttribute(CONFIG.ATTR)
        || this.$trigger.getAttribute('href').substring(1);
      const $target = document.getElementById(href) || document.querySelector(`[name="${href}"]`);

      if (!$target) {
        return;
      }

      e.preventDefault();
      $target.scrollIntoView({ block: 'start', behavior: 'smooth' });
    });
  }
}

function init() {
  const $triggers = [...window.document.querySelectorAll(`[${CONFIG.ATTR}]`)];
  $triggers.forEach(($trigger) => new ScrollTo($trigger));
}

export {
  init,
};
