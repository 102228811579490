/* global window document */

import { debounce } from '../services/Debounce';
import { onlyClosest } from '../services/OnlyClosest';
import { CONFIG as NAV_CONFIG } from './navigation';
import ScrollTo from './scroll-to';

export const CONFIG = {
  ATTR: 'data-hash-activate',
  ATTR_WRAPPER: 'data-hash-activate-wrapper',
  CLASS_ACTIVE: '-is-active',
  NAV_ATTR: NAV_CONFIG.ATTR,
  NAV_EVENT_RESIZE: NAV_CONFIG.EVENT_RESIZE,
};

class ScrollActivate {
  constructor($link) {
    this.$link = new ScrollTo($link);
    this.$wrapper = $link.closest(`[${CONFIG.ATTR_WRAPPER}]`);
    this.$nav = document.querySelector(`[${CONFIG.NAV_ATTR}]`);
    this.hash = this.getHash();
    this.$section = document.getElementById(this.hash)
      || document.querySelector(`[name="${this.hash}"]`).parentNode;
    this.isActive = false;
    this.isScrolling = false;
    this.offset = 0;

    if (!this.$section) {
      return;
    }

    this.scroll = this.scroll.bind(this);
    this.setActivity = this.setActivity.bind(this);
    this.init();
  }

  init() {
    window.addEventListener('load', () => {
      this.setActivity();
      window.requestAnimationFrame(this.scroll);
    });
    window.addEventListener('resize', debounce(this.setActivity));

    if (this.$nav) {
      this.$nav.addEventListener(CONFIG.NAV_EVENT_RESIZE, (e) => {
        const { detail: { height } } = e;
        this.setOffset(height + 1);
      });
    }

    if (!this.$wrapper) {
      return;
    }

    this.$wrapper.addEventListener('click', onlyClosest(`[${CONFIG.ATTR}]`, () => {
      this.isScrolling = true;

      let scrollTimeout;
      window.addEventListener('scroll', () => {
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
          this.isScrolling = false;
          this.setActivity();
        }, 100);
      });
    }));
  }

  getHash() {
    const href = this.$link.getAttribute('href') || '';
    return href.charAt(0) === '#' ? href.substring(1) : this.$link.getAttribute(CONFIG.ATTR);
  }

  get scrollTop() {
    return window.pageYOffset || document.documentElement.scrollTop;
  }

  setOffset(offset) {
    this.offset = offset;
  }

  setActivity() {
    if (this.isScrolling) {
      return;
    }

    const start = this.$section.getBoundingClientRect().top + this.scrollTop - this.offset;
    const end = start + this.$section.offsetHeight;

    if (this.scrollTop >= start && this.scrollTop < end) {
      if (this.isActive) {
        return;
      }

      this.activate(true);
    } else {
      if (!this.isActive) {
        return;
      }

      this.activate(false);
    }
  }

  activate(isActive) {
    this.isActive = isActive;
    this.$link.classList.toggle(CONFIG.CLASS_ACTIVE, isActive);

    if (!isActive || !this.$wrapper) {
      return;
    }

    const offset = this.$link.offsetLeft - this.$wrapper.offsetLeft
      - (this.$wrapper.offsetWidth / 2) + (this.$link.offsetWidth / 2);

    this.$wrapper.scrollTo({
      left: Math.max(offset, 0),
      behavior: 'smooth',
    });
  }

  scroll() {
    this.setActivity();
    window.requestAnimationFrame(this.scroll);
  }
}

function init() {
  const $links = [...window.document.querySelectorAll(`[${CONFIG.ATTR}]`)];
  $links.forEach(($link) => new ScrollActivate($link));
}

export default {
  ScrollActivate,
  init,
};
