/* eslint-disable jsx-a11y/no-static-element-interactions,
jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

const Marker = ({
  id, onSelect, isSelected, isHighlighted,
}) => {
  const mainClass = 'map-marker';
  const selectedClass = isSelected ? `${mainClass}--selected` : '';
  const highlightedClass = isHighlighted ? `${mainClass}--highlighted` : '';

  return (
    <div
      className={`${mainClass} ${selectedClass} ${highlightedClass}`}
      onClick={() => {
        onSelect(id);
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 43"
      >
        <path
          className="map-marker__body"
          d="M25.297 4.674C19.609-1 10.389-1 4.701 4.674-.424 9.789-1 19.422 3.351 25.209L15.001 42l11.65-16.791c4.346-5.787 3.77-15.42-1.354-20.535z"
        />
        <path
          className="map-marker__dot"
          d="M15 7a8 8 0 1 1 0 16 8 8 0 0 1 0-16z"
        />
      </svg>
    </div>
  );
};

Marker.propTypes = {
  id: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  isHighlighted: PropTypes.bool,
};

Marker.defaultProps = {
  isSelected: false,
  isHighlighted: false,
};

export default Marker;
