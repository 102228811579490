import Modal from './modal';

class OpenModal {
  constructor(options) {
    this.options = {
      modal: 'data-auto-modal',
      keyPrefixLocalStorage: 'modal-auto-count_',
      keyPrefixSessionStorage: 'modal-auto-session-count_',
      maxViews: 99999,
      maxViewsPerSession: 1,
      timeOffset: 1000 * 40,
      ...options,
    };
  }

  init = (window) => {
    const $autoModals = window.document.querySelectorAll(`[${this.options.modal}]`);

    [...$autoModals].forEach(($modal) => {
      const id = $modal.getAttribute('id');

      if (!id) {
        return;
      }

      const {
        keyPrefixSessionStorage,
        keyPrefixLocalStorage,
        maxViewsPerSession,
        maxViews,
      } = this.options;
      const keySessionStorage = `${keyPrefixSessionStorage}${id}`;
      const keyLocalStorage = `${keyPrefixLocalStorage}${id}`;
      let openInOtherTab = false;

      window.addEventListener('storage', (e) => {
        if (e.key !== keyLocalStorage) {
          return;
        }

        openInOtherTab = true;
      });

      setTimeout(() => {
        const sessionCount = parseInt((window.sessionStorage.getItem(keySessionStorage) || 0), 10);
        const totalCount = parseInt((window.localStorage.getItem(keyLocalStorage) || 0), 10);

        if (openInOtherTab || (sessionCount >= maxViewsPerSession) || (totalCount >= maxViews)) {
          return;
        }

        Modal.show(id);

        try {
          window.sessionStorage.setItem(
            keySessionStorage,
            (sessionCount + 1).toString(),
          );
          window.localStorage.setItem(
            keyLocalStorage,
            (totalCount + 1).toString(),
          );
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn('Your web browser does not support storing settings locally. In Safari, the most common cause of this is using "Private Browsing Mode". Some settings may not save or some features may not work properly for you.');
        }
      }, this.options.timeOffset);
    });
  }
}

export default OpenModal;
