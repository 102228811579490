const cache = new WeakMap();

// https://michalzalecki.com/react-memoized-event-handlers/
export default function memoize(fn, ...args) {
  const key = args[0];

  if (cache.has(key)) {
    return cache.get(key);
  }

  const innerFn = (...innerArgs) => fn.call(null, ...args, ...innerArgs);
  cache.set(key, innerFn);

  return innerFn;
}
