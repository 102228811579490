/* global window, CustomEvent */

import { tns } from 'tiny-slider/src/tiny-slider';
import Breakpoints from '../services/Breakpoints';
import { onlyClosest } from '../services/OnlyClosest';
import { debounce } from '../services/Debounce';

const CONFIG = {
  ATTR: 'data-slider',
  ATTR_CONTENT: 'data-slider-content',
  ATTR_DOT: 'data-slider-dot',
  ATTR_NAV: 'data-slider-nav',
  CLASS_DISABLED_NAV: '-is-disabled',
  CLASS_ACTIVE: '-is-active',
  CLASS_INIT: '-is-init',
  CLASS_ANIMATING: '-is-animating',
  CLASS_SLIDES_FIT: '-is-fitting',
  SPEED: 750,
  DURATION: 5000,
};

export const EVENT_SLIDE_CHANGE = 'slideChange';

const SliderComponent = {
  init() {
    const $sliders = window.document.querySelectorAll(`[${CONFIG.ATTR}]`);

    [...$sliders].forEach(($slider) => {
      const settings = {
        container: $slider.querySelector(`[${CONFIG.ATTR_CONTENT}]`),
        items: 1,
        slideBy: 1,
        loop: true,
        controls: false,
        nav: false,
        autoHeight: true,
        mouseDrag: true,
        speed: CONFIG.SPEED,
        autoplayTimeout: CONFIG.DURATION,
        ...this.getSettings($slider),
        onInit: (info) => {
          $slider.classList.add(CONFIG.CLASS_INIT);
          this.setFitting($slider, info);

          // wait for the `Slider` variable to get initialized.
          // the onInit is fired during the slider creation, so the
          // `tns` function hasn’t returned yet.
          setTimeout(() => {
            this.update($slider, Slider); // eslint-disable-line no-use-before-define
          }, 0);
        },
      };

      const Slider = tns(settings);
      Slider.settings = settings;

      Slider.events.on('indexChanged', () => {
        this.update($slider, Slider);
      });

      Slider.events.on('transitionStart', () => {
        $slider.classList.add(CONFIG.CLASS_ANIMATING);
      });

      Slider.events.on('transitionEnd', () => {
        $slider.classList.remove(CONFIG.CLASS_ANIMATING);
      });

      this.addEvents($slider, Slider);
    });

    return this;
  },

  getSettings($slider) {
    const data = $slider.getAttribute(CONFIG.ATTR);
    let settings = data ? JSON.parse(data) : {};

    if (settings.breakpoints) {
      settings = { ...settings, responsive: this.getResponsiveSettings(settings.breakpoints) };
    }

    return settings;
  },

  getResponsiveSettings(breakpoints) {
    return Object.keys(Breakpoints).reduce(
      (result, breakpoint) => (breakpoints[breakpoint]
        ? { ...result, [Breakpoints[breakpoint]]: breakpoints[breakpoint] } : result), {},
    );
  },

  update($slider, Slider) {
    const {
      index, items, slideItems,
    } = Slider.getInfo();

    const indexLast = (index + items) - 1;

    this.setActiveItems(slideItems, index, indexLast);
    this.setActiveItems($slider.querySelectorAll(`[${CONFIG.ATTR_DOT}]`), index, indexLast);

    slideItems[index].dispatchEvent(new CustomEvent(EVENT_SLIDE_CHANGE, {
      detail: {
        slides: slideItems,
        slider: Slider,
      },
    }));

    this.updateArrows($slider, Slider);
  },

  updateArrows($slider, Slider) {
    const { index, slideCount } = Slider.getInfo();
    const $nav = $slider.querySelectorAll(`[${CONFIG.ATTR_NAV}]`);

    if (!$nav.length) {
      return;
    }

    const [prev, next] = [...$nav];

    [prev, next].forEach((arrow) => arrow.classList.remove(CONFIG.CLASS_DISABLED_NAV));

    if (index === 0) {
      prev.classList.add(CONFIG.CLASS_DISABLED_NAV);
    }

    if (index === slideCount - 1) {
      next.classList.add(CONFIG.CLASS_DISABLED_NAV);
    }
  },

  setFitting($slider, info) {
    const {
      items, slideCount,
    } = info;

    if (items >= slideCount) {
      $slider.classList.add(CONFIG.CLASS_SLIDES_FIT);
      return;
    }

    $slider.classList.remove(CONFIG.CLASS_SLIDES_FIT);
  },

  setActiveItems($items, firstActiveIdx, lastActiveIdx) {
    [...$items].forEach(($item, idx) => {
      if (idx >= firstActiveIdx && idx <= lastActiveIdx) {
        $item.classList.add(CONFIG.CLASS_ACTIVE);
        return;
      }

      $item.classList.remove(CONFIG.CLASS_ACTIVE);
    });
  },

  addEvents($slider, Slider) {
    $slider.addEventListener('click', onlyClosest(`[${CONFIG.ATTR_NAV}]`, (e, $nav) => {
      if ($slider.classList.contains(CONFIG.CLASS_ANIMATING)) {
        return;
      }

      const target = $nav.getAttribute(CONFIG.ATTR_NAV);
      Slider.goTo(target);
    }));

    window.addEventListener('resize', debounce(() => {
      this.update($slider, Slider);
      this.setFitting($slider, Slider.getInfo());
    }, 250));
  },
};

export default SliderComponent;
