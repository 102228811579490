/* eslint-disable no-param-reassign */

import { debounce } from '../services/Debounce';

class FitFontSize {
  constructor(options) {
    this.options = {
      attr: 'data-fit-font-size',
      ratio: {
        min: 1,
        max: 2,
      },
      ...options,
    };
  }

  init(window) {
    this.elements = [...window.document.querySelectorAll(`[${this.options.attr}]`)];

    this.update(window);

    window.addEventListener('resize', debounce(this.update.bind(this, window), 250));
  }

  update(window) {
    this.elements.forEach((element) => {
      // set display to table, so element’s width extends over the parent container
      // clear the computed font size
      element.style.display = 'table';
      element.style.fontSize = '';

      const fontSize = parseInt(window.getComputedStyle(element).fontSize, 10);
      const padding = parseInt(window.getComputedStyle(element.parentNode).paddingLeft, 10) * 2;
      const ratio = element.offsetWidth / (element.parentNode.offsetWidth - padding);
      const effectiveRatio = Math.min(
        this.options.ratio.max,
        Math.max(this.options.ratio.min, ratio),
      );
      const targetSize = parseInt(fontSize / effectiveRatio, 0);

      if (targetSize !== fontSize) {
        element.style.fontSize = `${targetSize}px`;
      }
      element.style.display = '';
    });
  }
}

export default FitFontSize;
