/* global window document MutationObserver getComputedStyle */
import { ResizeObserver } from '@juggle/resize-observer';

import { debounce } from '../services/Debounce';

const CONFIG = {
  ATTR: 'data-sticky',
  ATTR_OFFSET: 'data-sticky-offset',
  ATTR_EL_TO: 'data-sticky-to', // ID of other sticky element to stick to underneath
  ATTR_EL_START: 'data-sticky-start', // ID of element that starts stickiness (top edge)
  ATTR_EL_END: 'data-sticky-end', // ID of element that stops stickiness (bottom edge)
  CLASS_STICKY: '-is-sticky',
};

class Sticky {
  constructor($el) {
    this.$el = $el;
    this.$elTo = document.getElementById($el.getAttribute(CONFIG.ATTR_EL_TO));
    this.$elStart = document.getElementById($el.getAttribute(CONFIG.ATTR_EL_START));
    this.$elEnd = document.getElementById($el.getAttribute(CONFIG.ATTR_EL_END));
    this.$placeholder = null;
    this.start = null;
    this.end = null;
    this.isAttached = false;
    this.stickToOffset = 0;
    this.viewportWidth = 0;

    this.update = this.update.bind(this);
    this.scroll = this.scroll.bind(this);
    this.setStickTo = this.setStickTo.bind(this);

    this.init();
  }

  init() {
    this.addEvents();

    if (!this.$elTo) {
      return;
    }

    const stickToResizeObserver = new ResizeObserver(this.setStickTo);
    const stickToMutationObserver = new MutationObserver(this.setStickTo);

    stickToResizeObserver.observe(this.$elTo);
    stickToMutationObserver.observe(this.$elTo, {
      attributes: true,
      childList: true,
      subtree: true,
    });
  }

  get scrollTop() {
    return window.pageYOffset || document.documentElement.scrollTop;
  }

  addEvents() {
    window.addEventListener('load', () => {
      this.update();
      window.requestAnimationFrame(this.scroll);
    });

    window.addEventListener('resize', debounce(this.update, 250));
  }

  update() {
    const { viewportWidth } = this;
    const newViewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    // break if viewport width didn't change
    if (newViewportWidth === viewportWidth) {
      return;
    }

    this.unStick();

    const {
      scrollTop,
      $el,
      $elStart,
      $elEnd,
    } = this;

    const isFixed = this.isFixed($el);
    const elOffset = ($elStart && $elStart.getBoundingClientRect().top + scrollTop) || 0;
    const offset = (parseInt($el.getAttribute(CONFIG.ATTR_OFFSET), 10) || 1) + elOffset;

    this.viewportWidth = newViewportWidth;
    this.start = ($el.getBoundingClientRect().top + scrollTop + offset) - ((isFixed && scrollTop) || 0); // eslint-disable-line max-len
    this.end = $elEnd && (($elEnd.getBoundingClientRect().top + scrollTop + $elEnd.offsetHeight) - offset - 100); // eslint-disable-line max-len

    this.setStickiness();
  }

  isFixed($el) {
    return window.getComputedStyle($el).position === 'fixed';
  }

  setPlaceholder() {
    const placeholderNeeded = ['static', 'relative'].indexOf(getComputedStyle(this.$el).position) >= 0;

    if (!placeholderNeeded) {
      return;
    }

    const height = this.$el.offsetHeight;
    this.$placeholder = document.createElement('div');
    this.$placeholder.style.height = `${height}px`;
    this.$el.parentNode.insertBefore(this.$placeholder, this.$el);
  }

  clearPlaceholder() {
    if (!this.$placeholder) {
      return;
    }

    this.$placeholder.parentElement.removeChild(this.$placeholder);
    this.$placeholder = null;
  }

  setStickTo() {
    const { $elTo } = this;

    if (!$elTo || !this.isFixed($elTo)) {
      return;
    }

    const top = parseInt(window.getComputedStyle($elTo).top, 10);
    this.stickToOffset = this.isFixed($elTo) ? ($elTo.offsetHeight + top) : 0;
  }

  setStickiness() {
    const { scrollTop } = this;

    if (
      scrollTop >= (this.start - this.stickToOffset)
      && (!this.end || scrollTop < (this.end + this.stickToOffset))
    ) {
      this.stick();
    } else {
      this.unStick();
    }
  }

  stick() {
    if (this.isAttached) {
      return;
    }

    this.setPlaceholder();
    this.isAttached = true;
    this.$el.style.top = `${this.stickToOffset}px`;
    this.$el.classList.add(CONFIG.CLASS_STICKY);
  }

  unStick() {
    if (!this.isAttached) {
      return;
    }

    if (this.$placeholder) {
      this.$placeholder.parentElement.removeChild(this.$placeholder);
      this.$placeholder = null;
    }

    this.$el.style.top = '';
    this.$el.classList.remove(CONFIG.CLASS_STICKY);
    this.isAttached = false;
  }

  scroll() {
    this.setStickiness();
    window.requestAnimationFrame(this.scroll);
  }
}

function init() {
  const $stickies = [...window.document.querySelectorAll(`[${CONFIG.ATTR}]`)];
  $stickies.forEach(($sticky) => new Sticky($sticky));
}

export default {
  Sticky,
  init,
};
