/* global google */
/* eslint-disable camelcase */
const isCountry = (type) => type === 'country';

const findCountry = ({ types }) => types.filter(isCountry).length;

const isShortCode = ({ short_name }) => short_name.length === 2;

const flattenResults = (accumulator, { address_components }) => [
  ...accumulator,
  ...address_components,
];

const first = (_) => _;

const getNames = (results) => results
  .reduce(flattenResults, [])
  .filter(findCountry)
  .filter(isShortCode)
  .find(first);

const geocodeCountry = (position) => {
  const geocoder = new google.maps.Geocoder();
  return new Promise((resolve, reject) => {
    geocoder.geocode(
      {
        location: {
          lat: position.latitude,
          lng: position.longitude,
        },
      },
      (results, status) => {
        if (status !== google.maps.GeocoderStatus.OK || !results.length) {
          reject();
          return;
        }
        const names = getNames(results);
        const { geometry: { bounds } } = results.find(findCountry);

        resolve({
          name: names.long_name,
          code: names.short_name,
          bounds,
        });
      },
    );
  });
};

const geocodeCity = (searchValue) => {
  const geocoder = new google.maps.Geocoder();
  return new Promise((resolve, reject) => {
    geocoder.geocode(
      {
        address: searchValue,
      },
      (results, status) => {
        if (status !== google.maps.GeocoderStatus.OK || !results.length) {
          reject();
          return;
        }

        resolve({
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        });
      },
    );
  });
};

export default geocodeCountry;

export {
  isCountry, findCountry, isShortCode, flattenResults, first, getNames, geocodeCountry, geocodeCity,
};
