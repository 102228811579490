/* global XMLHttpRequest */

export default function makeRequest(url, callback) {
  const request = new XMLHttpRequest();

  request.open('GET', url);
  request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

  request.onload = () => {
    if (request.status >= 200 && request.status < 400) {
      const response = request.responseText;

      callback({
        status: 'success',
        response,
      });
    } else {
      callback({
        status: 'error',
      });
    }
  };

  request.send();
}
