/* global window, document */

const CONFIG = {
  ATTR: 'data-animation',
  OFFSET: 0.05,
  CLASS_INACTIVE: '-is-animation-inactive',
  CLASS_ACTIVATED: '-has-animation-init',
};

const Animation = {
  sections: [],

  init() {
    this.addEventListeners();

    // bind methods
    this.drawLoop = this.drawLoop.bind(this);
    this.updateAnimations = this.updateAnimations.bind(this);

    return this;
  },

  addEventListeners() {
    window.addEventListener('load', () => {
      this.setSections();
      this.drawLoop();
    });

    window.addEventListener('resize', () => {
      this.updateAnimations();
    });
  },

  drawLoop() {
    this.scrollEvent();
    window.requestAnimationFrame(this.drawLoop);
  },

  setSections() {
    this.sections = [...window.document.querySelectorAll(`[${CONFIG.ATTR}]`)].map((item) => ({
      el: item,
      offset: item.getBoundingClientRect().top + this.getScrollPosition(),
      height: item.getBoundingClientRect().bottom - item.getBoundingClientRect().top,
    }));
  },

  updateAnimations() {
    this.sections = this.sections.map((sectionEntry) => (
      {
        ...sectionEntry,
        offset: sectionEntry.el.getBoundingClientRect().top + this.getScrollPosition(),
      }
    ));
  },

  getScrollPosition() {
    return window.scrollY || document.documentElement.scrollTop;
  },

  getSectionPercentage(section) {
    const sectionOffset = window.innerHeight - (section.offset - this.getScrollPosition());

    return sectionOffset / (window.innerHeight + section.height);
  },

  scrollEvent() {
    if (window.document.documentElement.clientWidth) {
      this.sections.forEach((section) => {
        const sectionPercentage = this.getSectionPercentage(section);

        if (sectionPercentage < 0 || sectionPercentage > 1) {
          section.el.classList.add(CONFIG.CLASS_INACTIVE);
          return;
        }

        if (sectionPercentage >= CONFIG.OFFSET) {
          section.el.classList.remove(CONFIG.CLASS_INACTIVE);
          section.el.classList.add(CONFIG.CLASS_ACTIVATED);
        }
      });
    }
  },
};

export default Animation;
