class SearchInput {
  constructor(options) {
    this.options = {
      trigger: 'data-search-trigger',
      close: 'data-search-input-close',
      container: 'data-search-input-container',
      input: 'data-search-input',
      className: '-is-visible',
      onOpen: () => {},
      onClose: () => {},
      onChange: () => {},
      ...options,
    };
  }

  init = (window) => {
    this.searchContainer = window.document.querySelector(`[${this.options.container}]`);
    this.input = this.searchContainer.querySelector(`[${this.options.input}]`);

    this.addEvents(window);
  };

  addEvents = (window) => {
    const trigger = window.document.querySelector(`[${this.options.trigger}]`);
    trigger.addEventListener('click', this.open);

    const closeButton = window.document.querySelector(`[${this.options.close}]`);
    closeButton.addEventListener('click', this.close);

    this.input.addEventListener('input', (event) => {
      this.options.onChange(event.target.value);
      this.options.onOpen();
    });
  };

  open = () => {
    this.searchContainer.classList.add(this.options.className);
    this.input.focus();
  };

  close = () => {
    this.searchContainer.classList.remove(this.options.className);
    this.options.onClose();
    this.input.value = '';
  }
}

export default SearchInput;
