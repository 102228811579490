/* global document, DOMParser */

class Notification {
  constructor(options) {
    this.options = {
      displayTime: 5000,
      animationTime: 1000,
      hideClassName: '-is-hidden',
      ...options,
    };
  }

  add = (message, type) => {
    const template = `<p class="notification notification--${type}">${message}</p>`;
    const parser = new DOMParser();
    const html = parser.parseFromString(template, 'text/html');
    const notification = html.querySelector('.notification');

    notification.addEventListener('click', () => {
      this.hide(notification);
    });

    document.body.insertAdjacentElement('beforeend', notification);

    setTimeout(() => {
      this.hide(notification);
    }, this.options.displayTime);
  };

  hide = (notification) => {
    notification.classList.add(this.options.hideClassName);

    setTimeout(() => {
      notification.remove();
    }, this.options.animationTime);
  };

  error = (message) => {
    this.add(message, 'error');
  };

  success = (message) => {
    this.add(message, 'success');
  };
}

export default Notification;
