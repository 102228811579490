import Geolocation from '../services/Geolocation';

class ClosestShowroom {
  constructor(options) {
    this.options = {
      container: 'data-closest-showroom',
      title: 'data-closest-showroom-title',
      address: 'data-closest-showroom-address',
      hours: 'data-closest-showroom-hours',
      button: 'data-closest-showroom-button',
      contactForm: null,
      contactFormUrl: 'data-contact-form-url',
      ...options,
    };
  }

  init = async (window) => {
    this.container = window.document.querySelector(
      `[${this.options.container}]`,
    );

    if (this.container) {
      const geolocation = new Geolocation();

      const mode = this.container.getAttribute(this.options.container);
      const location = await geolocation.findClosestLocation(mode);

      if (location && location.length) {
        this.setData(location[0]);
      }
    }
  };

  setData = ({
    id, street, city, openingHours,
  }) => {
    const title = this.container.querySelector(`[${this.options.title}]`);
    if (title) {
      title.innerHTML = title.getAttribute(this.options.title);
    }

    this.container.querySelector(`[${this.options.address}]`).innerHTML = `${street}, ${city}`;
    this.container.querySelector(`[${this.options.hours}]`).innerHTML = `${openingHours.split('\n').join('<br />')}`;

    const button = this.container.querySelector(`[${this.options.button}]`);
    if (button) {
      button.innerHTML = button.getAttribute(this.options.button);

      button.addEventListener('click', (e) => {
        e.preventDefault();

        this.options.contactForm.open(
          this.container.getAttribute(this.options.contactFormUrl),
          id,
        );
      });
    }
  };
}

export default ClosestShowroom;
