import SearchInput from './search-input';
import SearchResults from './search-results';

class Search {
  constructor(options) {
    this.options = {
      search: 'data-search',
      main: 'data-main',
      query: 'data-search-query',
      searchVisibleClass: '-has-results',
      mainHiddenClass: '-is-hidden',
      ...options,
    };
  }

  init(window) {
    (new SearchInput({
      onOpen: this.showResults,
      onClose: this.hideResults,
      onChange: this.updateQuery,
    })).init(window);

    (new SearchResults()).init(window);

    this.mainContainer = window.document.querySelector(`[${this.options.main}]`);
    this.searchContainer = window.document.querySelector(`[${this.options.search}]`);
    this.queryContainer = this.searchContainer.querySelector(`[${this.options.query}]`);
  }

  updateQuery = (query) => {
    this.queryContainer.innerText = query;
  };

  showResults = () => {
    this.mainContainer.classList.add(this.options.mainHiddenClass);
    this.searchContainer.classList.add(this.options.searchVisibleClass);
  };

  hideResults = () => {
    this.mainContainer.classList.remove(this.options.mainHiddenClass);
    this.searchContainer.classList.remove(this.options.searchVisibleClass);
  };
}

export default Search;
