/* global document */

import Plyr from 'plyr';

const CONFIG = {
  ATTR: 'data-player',
};

const Player = {
  players: [],

  init() {
    const $players = [...document.querySelectorAll(`[${CONFIG.ATTR}]`)];

    this.players = $players.map(($player) => {
      const type = $player.getAttribute(CONFIG.ATTR);

      const settings = {
        resetOnEnd: true,
        invertTime: false,
        loadSprite: false,
        iconPrefix: 'icon-player',
      };

      if (type === 'audio') {
        settings.controls = ['mute', 'play', 'progress', 'current-time'];
      }

      const player = new Plyr($player, settings);
      this.handlePlay(player);

      return player;
    });

    this.addEvents();
  },

  // pause all other players on player play
  handlePlay(item) {
    item.on('play', (e) => {
      this.players.forEach((player) => {
        if (!e.target.contains(player.elements.container)) {
          if (player.playing) {
            player.pause();
          }
        }
      });
    });
  },

  // handle video play on new elements show (e.g. video in modal)
  addEvents() {
    document.body.addEventListener('show', (e) => {
      this.players.forEach((player) => {
        if (e.target.contains(player.elements.container)) {
          if (!player.playing) {
            player.play();
          }
        }
      });
    }, true);

    // handle video pause on new elements hide (e.g. video in modal)
    document.body.addEventListener('hide', (e) => {
      this.players.forEach((player) => {
        if (e.target.contains(player.elements.container)) {
          if (player.playing) {
            player.stop();
          }
        }
      });
    }, true);
  },
};

export default Player;
