class ShowroomSelect {
  constructor(options) {
    this.options = {
      containerAttr: 'data-showroom-select',
      contentAttr: 'data-showroom-select-content',
      detailsAttr: 'data-details',
      ...options,
    };
  }

  init = (window) => {
    this.container = window.document.querySelector(`[${this.options.containerAttr}]`);

    if (!this.container) {
      return;
    }

    this.select = this.container.querySelector('select');

    this.addEvents();
    this.update();
  };

  addEvents = () => {
    this.select.addEventListener('change', () => {
      this.update();
    });
  };

  update = () => {
    this.renderContent();
  };

  getDataFromSelectedOption = () => JSON.parse(
    this.select.options[this.select.selectedIndex].getAttribute(
      this.options.detailsAttr,
    ),
  );

  renderContent = () => {
    this.container.querySelector(`[${this.options.contentAttr}]`).innerHTML = this.getDataFromSelectedOption();
  };

  setShowroom = (showroomId) => {
    this.select.value = showroomId;
    this.update();
  };
}

export default ShowroomSelect;
