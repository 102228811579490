class OpenShowroomContactForm {
  constructor(options) {
    this.options = {
      attr: 'data-open-contact-form',
      showroomContactForm: null,
      contactFormUrl: 'data-contact-form-url',
      ...options,
    };
  }

  init = (window) => {
    const links = [...window.document.querySelectorAll(`[${this.options.attr}]`)];

    links.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();

        this.options.showroomContactForm.open(
          link.getAttribute(this.options.contactFormUrl),
          null,
          link.getAttribute(this.options.attr),
        );
      });
    });
  }
}

export default OpenShowroomContactForm;
