/* global document */

import algoliasearch from 'algoliasearch';

const SEARCH_PARAMETERS = {
  architect: {
    facets: '["hasArchitect"]',
    facetFilters: '[["hasArchitect:true"]]',
  },
  default: {
    aroundRadius: 500 * 1000,
  },
};

class LocationSearch {
  constructor(algoliaAppId, algoliaApiKey, algoliaIndex) {
    const client = algoliasearch(algoliaAppId, algoliaApiKey);
    this.algolia = client.initIndex(algoliaIndex);
  }

  findNearestLocation = (lat, lng, mode) => this.algolia
    .search('', {
      aroundLatLng: `${lat}, ${lng}`,
      hitsPerPage: 1,
      ...(SEARCH_PARAMETERS[mode] || SEARCH_PARAMETERS.default),
    })
    .then((response) => response.hits);

  findLocationsInCountry = (countryCode) => this.algolia
    .search('', {
      facets: '["country"]',
      facetFilters: `[["country:${countryCode}"]]`,
    })
    .then((response) => response.hits);

  search = (query) => new Promise((resolve) => {
    this.algolia.search(
      query,
      {
        hitsPerPage: 3,
      },
    )
      .then(({ hits }) => {
        resolve(hits);
      });
  });
}

export default function LocationSearchFactory() {
  const settings = JSON.parse(
    document.querySelector('#algolia_settings').innerHTML,
  );

  const {
    id,
    key,
    index,
  } = settings;

  return new LocationSearch(id, key, index);
}
